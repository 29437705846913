import React from "react"
import Img from "gatsby-image"
import classNames from "classnames"

import styles from "../utils/staticPages.module.scss"

const HomeMediaContainer = ({ title, description, image }) => (
  <div className="column mb-3-mobile">
    <div className={classNames("has-text-centered",styles["homeMedia__imageContainer"])}>
      <Img fixed={image} />
    </div>
    <h5 className="mt-1 mb-2-mobile has-text-centered has-text-primary">
      {title}
    </h5>
    <p className="mx-3-mobile is-size-6 has-text-centered">{description}</p>
  </div>
)

export default HomeMediaContainer
