import React from "react"

import Layout from "layout"

import HomeBanner from "components/StaticPages/Home/HomeBanner"
import HomeFeatures from "components/StaticPages/Home/HomeFeatures"
import HomeAbout from "components/StaticPages/Home/HomeAbout"
import HomeHowToOrder from "../components/StaticPages/Home/HomeHowToOrder"

const IndexPage = () => {
  return (
    <Layout
      display={{ helpCenterBanner: false, footer: true }}
      seoTitle="Pulse Care Program"
    >
      <HomeBanner />
      <HomeFeatures />
      <HomeHowToOrder />
      <HomeAbout />
    </Layout>
  )
}

export default IndexPage
