import React from "react"
import ReactPlayer from "react-player"

import Hero from "layout/Hero"
import Container from "layout/Container"

import { GATSBY_HOME_VIDEO_URL_ENGLISH } from "gatsby-env-variables"

const HomeHowToOrder = () => {
  const URL = GATSBY_HOME_VIDEO_URL_ENGLISH

  return (
    <Hero size="" color="light">
      <Container isCentered desktop={10} fullhd={8}>
        <h1 className="has-text-weight-bold has-text-primary py-1 has-text-centered is-size-2">
          How To Order
        </h1>
        <div className="player-wrapper">
          <ReactPlayer width="100%" height="60vh" controls url={URL} />
        </div>
      </Container>
    </Hero>
  )
}

export default HomeHowToOrder
