import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const useHomeImages = () => {
  const data = useStaticQuery(graphql`
    {
      callCenter: file(relativePath: { eq: "call-center.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      healthSurvey: file(relativePath: { eq: "health-survey.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      diseaseEducation: file(relativePath: { eq: "disease-education.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      novartis: file(relativePath: { eq: "logos/novartis.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      medgrocer: file(relativePath: { eq: "logos/medgrocer-long.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}

export default useHomeImages
