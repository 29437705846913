import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GATSBY_ORDER_AS_GUEST_KEY } from "gatsby-env-variables"

import Hero from "layout/Hero"
import Container from "layout/Container"
import HomeCTAButton from "./HomeCTAButton"
import { isBrowser } from "../../../services/general"

const HomeBanner = () => {
  const data = useStaticQuery(graphql`
    {
      heart: file(relativePath: { eq: "index__hero--heart.jpg" }) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#ffffff", shadow: "#0d6d6e" }
            toFormat: PNG
            quality: 90
            maxWidth: 1000
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const heart = data?.heart?.childImageSharp?.fluid?.src

  useEffect(() => {
    if (isBrowser()) {
      window.sessionStorage.removeItem(GATSBY_ORDER_AS_GUEST_KEY)
    }
  }, [])

  return (
    <Hero
      size="medium"
      background={{
        image: heart,
        position: "center",
      }}
    >
      <Container isCentered desktop={12} fullhd={8}>
        <div className="columns">
          <div className="column is-8-desktop">
            <h3 className="title">
              {
                "The Pulse Care Program is a heart failure management and medicine adherence program."
              }
            </h3>

            <HomeCTAButton />
          </div>
        </div>
      </Container>
    </Hero>
  )
}

export default HomeBanner
