import React from "react"
import Img from "gatsby-image"

import Hero from "layout/Hero"
import Container from "layout/Container"

import useHomeImages from "../hooks/useHomeImages"
const HomeAbout = () => {
  const data = useHomeImages()
  const medgrocerLogo = data.medgrocer.childImageSharp.fixed
  const novartisLogo = data.novartis.childImageSharp.fixed

  return (
    <Hero size="">
      <Container isCentered desktop={10} fullhd={8}>
        <section className="columns">
          <div className="column content mx-1">
            <figure className="m-1">
              <Img fixed={medgrocerLogo} />
            </figure>
            <p className="has-text-left is-size-6">
              MedGrocer's digitally-enabled corporate healthcare solutions and
              patient programs empower our customers to get their medicines,
              vaccines, and teleconsultations conveniently, cost-effectively,
              and intelligently.
            </p>
            <p className="has-text-left is-size-6">
              Every year, we serve over one million Filipinos either directly or
              through partnerships with employers and pharmaceutical companies.
            </p>
          </div>
          <div className="column content mx-1">
            <figure className="m-1">
              <Img fixed={novartisLogo} />
            </figure>
            <p className="has-text-left is-size-6">
              Novartis is structured to deliver innovative products, explore
              global scale, and respond to new opportunities and risks. Our
              divisions - Innovative Medicines and Sandoz - are supported by
              functional organizations with global scale.
            </p>
            <p className="has-text-left is-size-6">
              Research and development (R&D) is at the core of our company and
              central to the Novartis strategy.
            </p>
          </div>
        </section>
      </Container>
    </Hero>
  )
}

export default HomeAbout
